export default {
  home: {
    minute: "minute",
    price_info:
      "The first {mianfei} minutes are free of charge. The maximum charge for 24 hours is {currencyName}{fengding}. Your {currencyName}{yajin} deposit will be automatically refunded after you return the power bank",
    feature_1: "Includes all types of cables: iPhone, Type-C, and Micro-USB",
    feature_2: "Can be returned at any station",
    feature_3:
      "Wireless charging available",
    tips_1:
      "Your {currencyName}{yajin} deposit will be returned at the end of the rental",
    tips_2:
      "Recharge deposit {currencyName}{yajin} can be used for rental, and when used up can be refunded",
    tips_3:
      "The current balance is {currencyName}{myyue}, which can be used for rental directly",
    submit: "Begin rental",
    borrow: "Rent",
    msg_fail_title: "Error",
    msg_fail_content: "Rental failed, please try again",
    msg_rentcount: "You currently have {zujieNum} orders that are not closed",
    goborrow: "Go to charge!!!",
    nearby: "View nearby stations",
    preauth: "Pre-authorization"
  },
  login: {
    code_title: "SMS verification",
    phone_number: "Phone Number",
    verification_code: "Please enter the verification code from SMS",
    captcha_code: "Code",
    send_code: "Send Code",
    login: "Login",
    agree: "By clicking Login, you agree to ",
    readme: "User Agreement",
    msg_fail_login: "Login failed",
    msg_success_login: "Login successful",
    msg_fail_user: "Error getting user information. Do you want to try again?",
    sign_in_with: "Sign in with",
    no_sms: "Did not receive the text message",
    retry: "Resend"
  },
  confirm: {
    deletecard: "Delete Card",
    addcard: "Enter card details",
    title: "Payment Confirmation",
    paynow: "Immediate payment",
    SMSConfirm:
      "Please pay according to the text message received on your phone and click the confirm button to continue.",
    pay_label: "Amount",
    auth_label: "Pre-authorization",
    price_tips:
      "Your balance can be withdrawn if requested. It might take 1-5 workdays to appear in your payment account.",
    auth_tips:
      "A pre-authorization amount of {0}{1} is compulsory. The actual rental amount will be deducted when the power bank is returned.",
    pay_type: "Please select your preferred payment method",
    submit: "Confirm payment",
    msg_loading: "Loading...",
    msg_fail_title: "Warning",
    msg_fail_payment: "Failed to initiate deposit payment",
    msg_fail_order: "Failed to create a rental order",
    msg_fail_support: "The system is not supported",
    msg_fail_confirm: "Confirm",
    msg_fail_retry: "Retry",
    msg_fail_cancel: "Cancel"
  },
  mine: {
    title: "User Profile",
    wallet: "My Wallet",
    balance: "My Balance",
    deposit: "Deposit",
    wallet_in: "Charge",
    wallet_out: "Withdraw",
    wallet_log: "Wallet Log",
    rental_history: "Rental History",
    payment_title: "Payment Methods",
    download_app: "Download the app",
    settings: "Settings",
    help: "Help",
    logout: "Logout",
    login_tips: "Please Log in",
    msg_fail_title: "Error",
    msg_fail_update: "Modification failed. Please try again",
    msg_loading: "Loading...",
    msg_fail_user: "Error getting user information. Do you want to try again?",
    edit_profile: "Edit Profile",
    join_us: "JOIN US",
    join_us_details: "Get a BeeCharge station at your venue"
  },
  msg: {
    appoffline: "The app has not been launched yet, please wait",
    deletecard: "Are you sure you want to delete this card?",
    no_phone: "Phone number is required",
    loading: "Loading...",
    title: "Hint",
    success: "Operation Successful",
    error: "Unknown error. Please try again later",
    roleerror: "Insufficient permissions. Please consult the administrator.",
    neterror: "Network connection failed. Please try again later",
    confirm: "Confirm",
    retry: "Retry",
    cancel: "Cancel",
    title_fail: "Warning",
    vieworder: "View order",
    success_payment: "Payment completed",
    success_buy: "Purchase completed",
    fail_payment: "Payment failure",
    keyword: "Keyword",
    ispayorder: "Please confirm if payment has been made",
    unpay: "Unpaid",
    yespay: "Paid",
    notopen: "Not yet open"
  },
  result: {
    tips:
      "To view orders and other station locations, please download the application.",
    download: "Download the app",
    title: "Rental confirmed",
    gotit: "Got It",
    price_plan: "Charge Standard",
    msg_success: "Successfully Rented",
    msg_tips_loading:
      "Power bank is being ejected, please wait..",
    msg_result_loading: "The payment result is being confirmed...",
    msg_fail_confirm: "Confirm",
    btn_usage: "Usage note",
    btn_return: "Return note",
    deposit: "Deposit",
    msg_fail_content: "Request failed. Please try again.",
    price_label: "per hour",
    price_info:
      "Free of charge for the first {mianfei}  minutes，A maximum dialy charge of {ccurrency}{fengding}. Deposit will be automatically deducted when rent reaches {ccurrency}{yajin}",
    tips_1:
      "Please collect the power bank No.{number} as shown in the picture",
    tips_2:
      "Please organize the cables and insert the power bank into the slot with the side marked with [downward arrows]",
    msg_note_usage:
      "The power bank is equipped with 3 types of cables, please choose the one you need for your device.",
    msg_note_return:
      "Please organize the cables and insert the power bank into the slot with the side marked with [downward arrows]",
    return_info:
      "When returning, ensure the cables are neatly arranged and insert the side with metal chips into an available socket until it reaches the bottom and stabilizes.",
    download_tips: "To rent our powerbank, you can choose one of the following options"
  },
  result_msg: {
    loading: "Loading...",
    refresh: "Refresh"
  },
  order: {
    title: "Rental History",
    in_use: "In Use",
    ended: "Ended",
    msg_error: "Request failed",
    order_number: "No.",
    order_details: "Order Details",
    order_zj_shop: "Venue rented",
    order_zj_time: "Time rented",
    order_gh_shop: "Venue returned",
    order_gh_time: "Time returned",
    order_use_time: "Use Times",
    order_amount: "Total amount",
    not_on_rent: "Not on rent",
    "renting": "In the lease",
    "undone": "Undone",
    "returned": "Returned",
    "questions": "Questions",
    "timeout_to_return": "Timeout to return",
    "suspended_for_billing": "Suspended for billing"
  },
  order_item: {
    order_number: "No.",
    venue_rented: "Pick-up location",
    time_rented: "Pick-up time",
    venue_returned: "Return location",
    time_returned: "Return time",
    charge_standard: "Charge standard",
    charge_standard_info:
      "{pJifei} {pCurrency} per hour (first {pMian} mins for free)",
    rent_occurred: "Rent occurred",
    returned: "Returned",
    unreturned: "Unreturned",
    type_0: "Not Completed",
    type_1: "Renting",
    type_2: "Canceled",
    type_3: "Returned",
    type_4: "Error occurred",
    type_5: "Overtime",
    min: "Min",
    payment: "Payment"
  },
  setting: {
    title: "Settings",
    lang: "Language"
  },
  help: {
    title: "FAQ",
    rentals: "Rentals",
    venues: "Venues",
    payments: "Payments"
  },
  wallet_in: {
    title: "Balance Recharge",
    price_label: "Recharge amount",
    price_placeholder: "Please enter the recharge amount",
    balance: "Current balance",
    price_auto: "Choose Payment Amount",
    price_tips:
      "Your balance can be withdrawn upon request. It might take 1-5 working days to appear in your payment account.",
    submit: "Submit",
    loading: "Loading...",
    paying: "Redirecting to the payment page..",
    msg_title: "Tips",
    msg_fail_order: "Failed to create recharge order",
    msg_fail_payment: "Failed to initiate recharge payment",
    msg_fail_retry: "Retry"
  },
  wallet_out: {
    title: "Withdrawal",
    price_label: "Withdrawal amount",
    price_placeholder: "Please enter the withdrawal amount",
    balance: "Current balance",
    balance_all: "Withdraw all",
    deposit: "Deposit",
    deposit_tips:
      "There is an outstanding rental order. The deposit of Ksh{pyajin} is non-refundable",
    submit: "Submit",
    apply: "Apply",
    confirm: "Confirm",
    loading: "Loading...",
    msg_fail_price: "The withdrawal amount is incorrect",
    msg_fail_balance:
      "The withdrawal amount cannot exceed the current balance",
    msg_fail_submit: "Please fill in all required informationn",
    msg_title: "Error",
    msg_success_content: "Refund Successful",
    confirmtitle: "Tips",
    confirmcontent:
      "The current wallet does not support cash withdrawal. Please record your contact information for manual refund processing",
    payee_name: "Payee Name",
    payee_bankcard: "Bank card number",
    payee_phone: "Phone",
    cancelText: "Cancel",
    confirmText: "Reapply"
  },
  map: {
    在线: "Open",
    离线: "Close",
    title: "Nearby Station",
    result_msg: "No station was found nearby",
    msg_title: "error",
    no_support: "Sorry! Your browser does not support location services",
    my_position: "My Location",
    distance: "Distance:",
    shop_time: "Hours",
    free: "Available for Rental",
    return: "Available empty slots",
    shop_title: "Venue details",
    address: "Address",
    go_here: "Go here",
    nav_help: "Help",
    nav_search: "Search"
  },
  scanQr: {
    NotAllowedError: 'You need to grant camera access',
    NotFoundError: 'There is no camera on this device',
    NotSupportedError: 'Security context required (HTTPS, localhost)',
    NotReadableError: 'The camera is in use',
    OverconstrainedError: 'Installing the camera is not appropriate',
    StreamApiNotSupportedError: 'This browser does not support the streaming API'
  },
  payment: {
    payment_title: "Payment Methods",
    pay_type: "Please add your crad details",
    add_payment_card: "Add Payment Card",
    add_payment: "add payment"
  },
  shop: {
    title: "Nearby Station",
    search_station: "Search for a station",
    search_station_tips: "Please enter the station name",
    shop_power_banks: "Power Banks",
    shop_empty_slots: "Empty Slots",
    shop_address: "Address",
    shop_business_hours: "Business Hours",
    shop_directions_station: "Get directions to station",
    shop_scan_qrcode: "Scan QR code"
  }
};
